html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

a,
button {
  width: 100%;
  cursor: pointer;
}

a.primary,
button.primary {
  background-color: #431363;
  color: white;
}

a.primary-inverted,
button.primary-inverted {
  border: solid 1px #431363;
  color: #431363;
  background-color: #43136315;
}

a.primary-inverted:hover,
button.primary-inverted:hover {
  border: solid 1px #431363;
  color: #431363;
  background-color: white;
}

a.primary-inverted:active,
button.primary-inverted:active {
  border: solid 1px #431363;
  color: #431363;
  background-color: #43136315;
}

a.secondary,
button.secondary {
  background-color: #902A89;
  color: white;
}

a.secondary-inverted,
button.secondary-inverted {
  border: solid 1px #902A89;
  color: #902A89;
  background-color: #902a8914;
}

a.secondary-inverted:hover,
button.secondary-inverted:hover {
  border: solid 1px #902A89;
  color: #902A89;
  background-color: white;
}

a[disabled],
button[disabled] {
  pointer-events: none;
}

div[disabled] {
  display: none;
}

input:focus,
select:focus {
  border-color: #431363 !important;
}

input[disabled] {
  background-color: #efefef !important;
}

input[type=checkbox],
select {
  cursor: pointer;
}

li {
  animation: slide 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

li.primary {
  color: #431363;
}

option {
  padding: 0 2px;
}

svg {
  display: block;
}

ul {
  font-size: .75em;
}

ul b {
  margin-right: 5px;
}

#bank {
  cursor: pointer;
}

.absolute {
  position: absolute;
}

.alert {
  color: red;
}

.bank {
  display: inline-block;
  width: 150px;
  height: 150px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: solid 1px #d1d1d1;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: top;
}

.bank:hover {
  border: solid 1px #929292;
}

.bank .image {
  display: block;
  width: 100%;
  margin: auto 0;
}

.bank .image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.bank-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.bank-column {
  text-align: center;
}

.bank-name {
  font-size: .80em;
  text-align: center;
}

.corner-logo {
  left: 0;
  margin-top: 5px;
  width: 30px;
  height: 30px;
}

.cover {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #000000a1;
  animation: fadein 75ms linear;
}

.fs-25 {
  font-size: 1.25em;
}

.header {
  width: 100%;
  height: 20px;
  margin: 10px 0;
  background-color: #431363;
  border-radius: 3px;
}

.hr {
  width: 100%;
  height: 0;
  border-top: solid 1px #431363;
  margin: 10px 0;
}

.loader {
  position: absolute;
  left: calc(50% - 12.5px);
  top: calc(50% - 12.5px);
  border: 2px solid #cbcbcb;
  border-top: 2px solid #902A89;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  animation: spin 1s linear infinite;
}

.loader[disabled],
.loader-alone[disabled] {
  display: none;
}

.loader-alone {
  left: 0;
  margin-top: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 2px solid #cbcbcb;
  border-top: 2px solid #902A89;
  box-sizing: border-box;
  animation: spin 1.1s forwards infinite;
}

.modal {
  max-width: calc(100% - 25px);
  max-height: calc(100vh - 25px);
  padding: 10px;
  border-radius: 3px;
  background-color: white;
  border: solid 2px #431363;
  overflow: auto;
  box-sizing: border-box;
  font-size: .8em;
}

.modal-overlay {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-overlay[disabled] {
  display: none;
}

.panel {
  padding: 25px;
  background-color: white;
  border: 2px solid #431363;
  border-radius: 10px;
}

.pending-field {
  margin-bottom: 15px !important;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.w-1 {
  width: 100%;
}

.w-1-2 {
  width: 50%;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}